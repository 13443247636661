.ol-box {
  box-sizing: border-box;
  border-radius: 2px;
  border: 1.5px solid rgb(179,197,219);
  background-color: rgba(255,255,255,0.4);
}

.ol-mouse-position {
  top: 8px;
  right: 8px;
  position: absolute;
}

.ol-scale-line {
  background: rgba(0,60,136,0.3);
  border-radius: 4px;
  bottom: 8px;
  left: 8px;
  padding: 2px;
  position: absolute;
}
.ol-scale-line-inner {
  border: 1px solid #eee;
  border-top: none;
  color: #eee;
  font-size: 10px;
  text-align: center;
  margin: 1px;
  will-change: contents, width;
  transition: all 0.25s;
}
.ol-scale-bar {
  position: absolute;
  bottom: 8px;
  left: 8px;
}
.ol-scale-step-marker {
  width: 1px;
  height: 15px;
  background-color: #000000;
  float: right;
  z-Index: 10;
}
.ol-scale-step-text {
  position: absolute;
  bottom: -5px;
  font-size: 12px;
  z-Index: 11;
  color: #000000;
  text-shadow: -2px 0 #FFFFFF, 0 2px #FFFFFF, 2px 0 #FFFFFF, 0 -2px #FFFFFF;
}
.ol-scale-text {
  position: absolute;
  font-size: 14px;
  text-align: center;
  bottom: 25px;
  color: #000000;
  text-shadow: -2px 0 #FFFFFF, 0 2px #FFFFFF, 2px 0 #FFFFFF, 0 -2px #FFFFFF;
}
.ol-scale-singlebar {
  position: relative;
  height: 10px;
  z-Index: 9;
  box-sizing: border-box;
  border: 1px solid black;
}

.ol-unsupported {
  display: none;
}
.ol-viewport, .ol-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.ol-selectable {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.ol-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}
.ol-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}
.ol-control {
  position: absolute;
  background-color: rgba(255,255,255,0.4);
  border-radius: 4px;
  padding: 2px;
}
.ol-control:hover {
  background-color: rgba(255,255,255,0.6);
}
.ol-zoom {
  top: .5em;
  left: .5em;
}
.ol-rotate {
  top: .5em;
  right: .5em;
  transition: opacity .25s linear, visibility 0s linear;
}
.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s linear, visibility 0s linear .25s;
}
.ol-zoom-extent {
  top: 4.643em;
  left: .5em;
}
.ol-full-screen {
  right: .5em;
  top: .5em;
}

.ol-control button {
  display: block;
  margin: 1px;
  padding: 0;
  color: white;
  font-weight: bold;
  text-decoration: none;
  font-size: inherit;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: .4em;
  background-color: rgba(0,60,136,0.5);
  border: none;
  border-radius: 2px;
}
.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}
.ol-zoom-extent button {
  line-height: 1.4em;
}
.ol-compass {
  display: block;
  font-weight: normal;
  font-size: 1.2em;
  will-change: transform;
}
.ol-touch .ol-control button {
  font-size: 1.5em;
}
.ol-touch .ol-zoom-extent {
  top: 5.5em;
}
.ol-control button:hover,
.ol-control button:focus {
  text-decoration: none;
  background-color: rgba(0,60,136,0.7);
}
.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}
.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}


.ol-attribution {
  text-align: right;
  bottom: .5em;
  right: .5em;
  max-width: calc(100% - 1.3em);
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
}
.ol-attribution a {
  color: rgba(0,60,136,0.7);
  text-decoration: none;
}
.ol-attribution ul {
  margin: 0;
  padding: 1px .5em;
  color: #000;
  text-shadow: 0 0 2px #fff;
  font-size: 12px;
}
.ol-attribution li {
  display: inline;
  list-style: none;
}
.ol-attribution li:not(:last-child):after {
  content: " ";
}
.ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle;
}
.ol-attribution button {
  flex-shrink: 0;
}
.ol-attribution.ol-collapsed ul {
  display: none;
}
.ol-attribution:not(.ol-collapsed) {
  background: rgba(255,255,255,0.8);
}
.ol-attribution.ol-uncollapsible {
  bottom: 0;
  right: 0;
  border-radius: 4px 0 0;
}
.ol-attribution.ol-uncollapsible img {
  margin-top: -.2em;
  max-height: 1.6em;
}
.ol-attribution.ol-uncollapsible button {
  display: none;
}

.ol-zoomslider {
  top: 4.5em;
  left: .5em;
  height: 200px;
}
.ol-zoomslider button {
  position: relative;
  height: 10px;
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  left: 0.5em;
  bottom: 0.5em;
}
.ol-overviewmap.ol-uncollapsible {
  bottom: 0;
  left: 0;
  border-radius: 0 4px 0 0;
}
.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
  display: block;
}
.ol-overviewmap .ol-overviewmap-map {
  border: 1px solid #7b98bc;
  height: 150px;
  margin: 2px;
  width: 150px;
}
.ol-overviewmap:not(.ol-collapsed) button {
  bottom: 2px;
  left: 2px;
  position: absolute;
}
.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
  display: none;
}
.ol-overviewmap:not(.ol-collapsed) {
  background: rgba(255,255,255,0.8);
}
.ol-overviewmap-box {
  border: 2px dotted rgba(0,60,136,0.7);
}

.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move;
}

/* OpenLayers pop-up (ol-popup) styles */
/* prettier-ignore */
.ol-popup {
    display: none;
    position: absolute;
    background-color: white;
    padding: 15px 25px 15px 15px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
}
.ol-popup:after, .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}
.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}
.ol-popup-content {
    min-width: 170px;
    max-height: 200px;
    overflow-x: auto;
}
.ol-popup-closer {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 150%;
    padding: 0 4px;
    color: gray;
    text-decoration: none;
}
.ol-popup-closer:after {
    content: "\2716";
}
.ol-popup div.infoResult {
    min-width: 130px;
}
.ol-popup div.infoResult p {
    padding: 0.1em;
    margin: 0;
}
.ol-popup-content h3 {
    margin: 0.25em 0;
}
.ol-popup.marker {
    margin-bottom: 30px;
}
.layer-switcher {
  position: absolute;
  top: 3.5em;
  right: 0.5em;
  text-align: left;
}
.layer-switcher .panel {
  margin: 0;
  border: 4px solid #eee;
  border-radius: 4px;
  background-color: white;
  display: none;
  max-height: inherit;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}
.layer-switcher button {
  float: right;
  z-index: 1;
  width: 38px;
  height: 38px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==')
    ;
  background-repeat: no-repeat;
  background-position: 2px;
  background-color: white;
  color: black;
  border: none;
}
.layer-switcher button:focus,
.layer-switcher button:hover {
  background-color: white;
}
.layer-switcher.shown {
  overflow-y: hidden;
}
.layer-switcher.shown.ol-control {
  background-color: transparent;
}
.layer-switcher.shown.ol-control:hover {
  background-color: transparent;
}
.layer-switcher.shown .panel {
  display: block;
}
.layer-switcher.shown button {
  display: none;
}
.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  display: block;
  background-image: unset;
  right: 2px;
  position: absolute;
  background-color: #eee;
  margin: 0 1px;
}
.layer-switcher.shown button:focus,
.layer-switcher.shown button:hover {
  background-color: #fafafa;
}
.layer-switcher ul {
  list-style: none;
  margin: 1.6em 0.4em;
  padding-left: 0;
}
.layer-switcher ul ul {
  padding-left: 1.2em;
  margin: 0.1em 0 0 0;
}
.layer-switcher li.group + li.group {
  margin-top: 0.4em;
}
.layer-switcher li.group + li.layer-switcher-base-group {
}
.layer-switcher li.group > label {
  font-weight: bold;
}
.layer-switcher.layer-switcher-group-select-style-none li.group > label {
  padding-left: 1.2em;
}
.layer-switcher li {
  position: relative;
  margin-top: 0.3em;
}
.layer-switcher li input {
  position: absolute;
  left: 1.2em;
  height: 1em;
  width: 1em;
  font-size: 1em;
}
.layer-switcher li label {
  padding-left: 2.7em;
  padding-right: 1.2em;
  display: inline-block;
  margin-top: 1px;
}
.layer-switcher label.disabled {
  opacity: 0.4;
}
.layer-switcher input {
  margin: 0px;
}
.layer-switcher.touch ::-webkit-scrollbar {
  width: 4px;
}
.layer-switcher.touch ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.layer-switcher.touch ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
li.layer-switcher-base-group > label {
  padding-left: 1.2em;
}
.layer-switcher .group button {
  position: absolute;
  left: 0;
  display: inline-block;
  vertical-align: top;
  float: none;
  font-size: 1em;
  width: 1em;
  height: 1em;
  margin: 0;
  background-position: center 2px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAW0lEQVR4nGNgGAWMyBwXFxcGBgaGeii3EU0tXHzPnj1wQRYsihqQ+I0ExDEMQAYNONgoAN0AmMkNaDSyQSheY8JiaCMOGzE04zIAmyFYNTMw4A+DRhzsUUBtAADw4BCeIZkGdwAAAABJRU5ErkJggg==');
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
.layer-switcher .group.layer-switcher-close button {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
.layer-switcher .group.layer-switcher-fold.layer-switcher-close > ul {
  overflow: hidden;
  height: 0;
}
/*layerswitcher on the right*/
.layer-switcher.shown.layer-switcher-activation-mode-click {
  padding-left: 34px;
}
.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  left: 0;
  border-right: 0;
}
/*layerswitcher on the left*/
/*
.layer-switcher.shown.layer-switcher-activation-mode-click {
  padding-right: 34px;
}
.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  right: 0;
  border-left: 0;
}
*/
.autocomplete__wrapper{position:relative}
.autocomplete__wait-result input{background-image:url(data:image/gif;base64,R0lGODlhIgAOAIQRAOjp6dTW19ze3+Xm54iKjevs7IKEh/f396Olp5ianPz8/Judn9fY2YGDhp+ho4CChdHT1P///////////////////////////////////////////////////////////yH/C05FVFNDQVBFMi4wAwEAAAAh/hFDcmVhdGVkIHdpdGggR0lNUAAh+QQJMgAfACwAAAEAIgAMAAAFVOAnjmRpnmiqrmzrighBICYgCEV956jz/A8HCQApQgBD4xFlAD4MpIBSEJ2iGs5G1cjYFrunhXNBGiiRI7MRbTokfokDSTEIBAYluh3/6vv/gIEfIQAh+QQJMgAfACwAAAEAIgAMAAAFWuAnjmRpnmiqrmzrioUgACZCEIgJyEUKQEAIbeR4GB8O0i84NAmCkADJcHwYSAGoAMWASkeNagMLZaCWwAFpUV2QBtCmaRAIDBSkQ8KYOJAUdHYvg4SFhociIQAh+QQBMgAfACwAAAEAIgAMAAAFWeAnjmRpnmiqrmzrioUgAGY8mwhBICkA/RDayAcUihyP5MOBEgAhAZITGB0ZlA8DivGsirhUUgPbQBF/A9IZkh4tsIvUIBAYKErz+n10SCQTBy+Cg4SFhh8hADs=);background-repeat:no-repeat;background-position-x:right;background-position-y:center}
.autocomplete__hint,.autocomplete__input{-webkit-appearance:none;border:2px solid #0b0c0c;border-radius:0;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;margin-bottom:0;width:100%}
.autocomplete__input{background-color:transparent;position:relative}
.autocomplete__hint{color:#b1b4b6;position:absolute}
.autocomplete__input--default{padding:5px}
.autocomplete__input--focused{outline:3px solid #fd0;outline-offset:0;box-shadow:inset 0 0 0 2px}
.autocomplete__input--show-all-values{padding:5px 34px 5px 5px;cursor:pointer}
.autocomplete__dropdown-arrow-down{z-index:-1;display:inline-block;position:absolute;right:8px;width:24px;height:24px;top:10px}
.autocomplete__menu{background-color:#fff;border:2px solid #0b0c0c;border-top:0;color:#0b0c0c;margin:0;max-height:342px;overflow-x:hidden;padding:0;width:100%;width:calc(100% - 4px)}
.autocomplete__menu--visible{display:block}
.autocomplete__menu--hidden{display:none}
.autocomplete__menu--overlay{box-shadow:rgba(0,0,0,.256863) 0 2px 6px;left:0;position:absolute;top:100%;z-index:100}
.autocomplete__menu--inline{position:relative}
.autocomplete__option{border-bottom:solid #b1b4b6;border-width:1px 0;cursor:pointer;display:block;position:relative}
.autocomplete__option>*{pointer-events:none}
.autocomplete__option:first-of-type{border-top-width:0}
.autocomplete__option:last-of-type{border-bottom-width:0}
.autocomplete__option--odd{background-color:#fafafa}
.autocomplete__option--focused,.autocomplete__option:hover{background-color:#1d70b8;border-color:#1d70b8;color:#fff;outline:0}
.autocomplete__option--no-results{background-color:#fafafa;color:#646b6f;cursor:not-allowed}
.autocomplete__hint,.autocomplete__input,.autocomplete__option{font-size:16px;line-height:1.25}
.autocomplete__hint,.autocomplete__option{padding:5px}
@media (min-width:641px){.autocomplete__hint,.autocomplete__input,.autocomplete__option{font-size:19px;line-height:1.31579}}
/* ol-ishare (oli) styles */
.ol-popup-content h3 {
  border-bottom: 1px solid lightgrey;
}
.ol-popup .feature {
  margin-left: 0.5em;
  margin-bottom: 0.5em;
}
.ol-popup .feature {
  min-width: 130px;
}
.ol-popup .feature p {
  padding: 0.1em;
  margin: 0;
}
.ol-popup.ol-popup-tooltip {
  padding-right: 15px;
}
.ol-popup.ol-popup-tooltip .ol-popup-content {
  min-width: inherit;
}
.ol-popup.ol-popup-tooltip .ol-popup-closer {
  display: none;
}
.ol-popup.ol-popup-tooltip .ol-popup-content {
  white-space: nowrap;
}
.ol-popup-content .loading {
  background-image: url("data:image/gif;base64,R0lGODlhIgAOAIQRAOjp6dTW19ze3+Xm54iKjevs7IKEh/f396Olp5ianPz8/Judn9fY2YGDhp+ho4CChdHT1P///////////////////////////////////////////////////////////yH/C05FVFNDQVBFMi4wAwEAAAAh/hFDcmVhdGVkIHdpdGggR0lNUAAh+QQJMgAfACwAAAEAIgAMAAAFVOAnjmRpnmiqrmzrighBICYgCEV956jz/A8HCQApQgBD4xFlAD4MpIBSEJ2iGs5G1cjYFrunhXNBGiiRI7MRbTokfokDSTEIBAYluh3/6vv/gIEfIQAh+QQJMgAfACwAAAEAIgAMAAAFWuAnjmRpnmiqrmzrioUgACZCEIgJyEUKQEAIbeR4GB8O0i84NAmCkADJcHwYSAGoAMWASkeNagMLZaCWwAFpUV2QBtCmaRAIDBSkQ8KYOJAUdHYvg4SFhociIQAh+QQBMgAfACwAAAEAIgAMAAAFWeAnjmRpnmiqrmzrioUgAGY8mwhBICkA/RDayAcUihyP5MOBEgAhAZITGB0ZlA8DivGsirhUUgPbQBF/A9IZkh4tsIvUIBAYKErz+n10SCQTBy+Cg4SFhh8hADs=");
  width: 37px;
  height: 14px;
}
/* Styling for custom LayerSwitcher implementation */
.layer-switcher .layer .legend {
  display: block;
  margin-left: 1.2em;
}
.layer-switcher .layer .legend.fail {
  display: none;
}
.layer-switcher.legends-static li label {
  padding-left: 1.2em;
}
.layer-switcher.legends-static .layer-switcher-base-group li label {
  padding-left: 2.7em;
}
/* Override accessible-autocomplete styles for Gazetteer control */
.oli-gazetteer .autocomplete__input {
  background-color: white;
}
.oli-gazetteer .autocomplete__hint,
.oli-gazetteer .autocomplete__input {
  border: 4px solid #eee;
}
.oli-gazetteer .autocomplete__input--focused {
  outline: none;
  box-shadow: inset 0 0 0 2px #bbb;
}
.oli-gazetteer .autocomplete__hint,
.oli-gazetteer .autocomplete__input,
.oli-gazetteer .autocomplete__option {
  font-size: inherit;
}
/* Required to override accessible-autocomplete without resorting to !important */
@media (min-width: 641px) {
  .oli-gazetteer .autocomplete__hint,
  .oli-gazetteer .autocomplete__input,
  .oli-gazetteer .autocomplete__option {
    font-size: inherit;
  }
}
.oli-gazetteer .autocomplete__menu {
  border-color: #eee;
}
.oli-gazetteer .autocomplete__option--focused,
.oli-gazetteer .autocomplete__option:hover {
  background-color: #1d70b8;
  border-color: #1d70b8;
  color: white;
  outline: none;
}
.oli-gazetteer.ol-control {
  top: 0.5em;
  left: inherit;
  right: 0.5em;
  width: 50%;
  max-width: 50%;
}
.oli-history.ol-control {
  top: 0.5em;
  left: 2.5em;
  display: flex;
  flex-direction: row;
}
.ol-control button.oli-disabled {
  opacity: 30%;
  pointer-events: none;
}

@charset "UTF-8";.mktoast__board{position:fixed;font-family:Roboto,Arial,Helvetica,sans-serif;width:300px;z-index:9000}.mktoast__board.mk-bottom{bottom:0}.mktoast__board.mk-top{top:0}.mktoast__board.mk-left{left:0}.mktoast__board.mk-right{right:0}.mktoast__board.mk-center{left:50%;margin-left:-150px}.mktoast__board.mk-bottom .mktoast-message{margin-top:0}@media (max-width:375px){.mktoast__board{width:100%}}.mktoast-message__wrapper{transition:all .3s;overflow:hidden}.mktoast-message{background-color:#eceff1;border:1px solid #cfd6db;box-shadow:2px 2px 10px rgba(0,0,0,.2);margin:10px;position:relative;overflow:hidden}.mktoast-message>h2,.mktoast-message__title{font-size:inherit;margin:0;padding:5px 10px;background-color:#dde3e6}.mktoast-message__body{padding:10px;max-height:200px;overflow:auto}.mktoast-message .mk-close{padding:0;cursor:pointer;text-decoration:none;color:inherit;position:absolute;top:0;right:2px;font-size:25px;line-height:25px}.mktoast-message .mk-close:after{content:"×"}.mktoast-message_danger{background-color:#dd3b2f;border-color:#ba291f;color:#fff}.mktoast-message_danger .mktoast-message__title{background-color:#d02e22}.mktoast-message_success{background-color:#7daa48;border-color:#628639;color:#fff}.mktoast-message_success .mktoast-message__title{background-color:#709841}.mktoast-message_warning{background-color:#e69621;border-color:#bf7a15;color:#fff}.mktoast-message_warning .mktoast-message__title{background-color:#d68918}.mktoast-message_info{background-color:#2b79be;border-color:#215f95;color:#fff}.mktoast-message_info .mktoast-message__title{background-color:#266caa}
html, body {
    height: 100%;
    padding: 0;
    margin: 0;
    overflow-y: hidden;
    font-family: sans-serif;
    font-size: small;
}
dl {
    margin-left: auto;
    margin-right: auto;
}
dt, dd {
    float: left;
}
dt {
    clear: left;
}

button {
    cursor: pointer;
}
table{
    display: block;
}

#map {
    position: absolute;
    top: 0em;
    left: 0em;
    height: 100%;
    width: calc(100% - 440px);
}
#map.sidebarOpen{
    width: calc(100% - 440px);
}
#sidebar {
    position: absolute;
    top: 0em;
    right: 0em;
    width: 440px;
    bottom: 0em;
    background-color: #ffffff;
    z-index: 3;
}

#sidebar.sidebarOpen {
    position: absolute;
    top: 0em;
    right: 0em;
    width: 440px;
    bottom: 0em;
    background-color: #ffffff;
}

#sidebarCloser {
    display: none;
}

#sidebarButton {
    z-index: 2;
    font-family: 'Material Icons Outlined';
    display: none;
}

#sidebarButton.active {
    display: block;
}

#sidebar .ol-control button {
    background-color: #0060df;
    color: #fff;
}

#sidebar .ol-control button:hover {
    outline: none;
}

#logo {
  display: block;
  width: calc(100% - 2em);
  height: auto;
  padding: 1em;
}

#getStarted {
    margin-top: 2em;
    margin-bottom: 2em;
    color: #fff;
}

#buttonBar {
    position: absolute;
    top: 0.4em;
    left: 0.4em;
    right: 0.4em;
    height: 3em;
}

.ol-control.btn-control.btn-active button {
  background-color: rgba(0,60,136,0.75);
}

.ol-control.control-btn-lnk a {
  display: block;
  margin: 1px;
  padding: 0;
  color: white;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  line-height: 1em;
  background-color: rgba(0,60,136,0.5);
  border: none;
  border-radius: 2px;
  padding: 0.25em;
}

#buttonBar .ol-control button, #buttonBar .ol-control a {
    font-size: large;
    background-color: #fff;
    color: #000;
}

#buttonBar .ol-control {
    padding: 0em;
    background: none;
}

#buttonBar .ol-control.btn-measure {
    left: 0.5em;
    height: 2em;
    padding-top: 0.25em;
    transform: rotate(-45deg);
}
#buttonBar .ol-control.btn-measure button, #buttonBar .ol-control.btn-share a, #buttonBar .ol-control.btn-help a, #buttonBar .ol-control.btn-docs a, #buttonBar .ol-control.btn-reset a, #buttonBar .ol-control.btn-print a, #buttonBar .ol-control.oli-history button {
    font-family: 'Material Icons Outlined';
    font-size: x-large;
    font-weight: normal;
    font-style: normal;
    color: #999;
}

.ol-control.oli-history button {
    font-family: 'Material Icons Outlined';
}
#buttonBar .ol-control.btn-measure button:hover, #buttonBar .ol-control.btn-share a:hover, #buttonBar .ol-control.btn-help a:hover, #buttonBar .ol-control.btn-docs a:hover, #buttonBar .ol-control.btn-reset a:hover, #buttonBar .ol-control.btn-print a:hover, #buttonBar .ol-control.oli-history button:hover {
    color: #666;
}

.ol-control.btn-share {
  left: 3.25em;
}

.ol-control.btn-help {
  left: 6em;
}

.ol-control.btn-print {
  left: 8.75em;
}

.ol-control.btn-reset {
  left: 11.5em;
}

.ol-control.btn-docs {
  left: 14.25em;
}

#sidebar .layer-switcher.shown {
  max-height: unset;
}
.layer-switcher.shown {
  box-sizing: border-box;
  top: 7em;
  right: 0.65em;
  width: calc(100% - 1.5em);
  max-width: calc(100% - 1.5em);
  height: calc(100% - 8em);
}

.layer-switcher.shown .panel {
  overflow-y: scroll;
  border-width: 1px;
  border-color: #ddd;
}
.layer-switcher.shown.layer-switcher-activation-mode-click > button {
    display: none;
}
.layer-switcher.shown.layer-switcher-activation-mode-click {
    padding-left: inherit;
}
.layer-switcher.shown .panel ul {
  overflow-y: visible;
}
#sidebar .layer-switcher-close button {
    margin-top: 4px;
}
#sidebar .layer-switcher li input {
    margin-top: 4px;
}
#sidebar .layer-switcher li label {
    margin-top: 3px;
}
.layer-switcher .layer a {
  position: absolute;
  right: 0em;
  top: 0em;
  text-decoration: none;
  font-size: large;
  padding-right: 0.5em;
  font-family: 'Material Icons Outlined';
  color: #aaa;
}

.layer-switcher .layer a:hover {
  color: #666;
}

.layerInfoPanel {
  position: absolute;
  top: 0em;
  left: 0em;
  bottom: 0em;
  right: 0em;
  background-color: white;
  visibility: hidden;
  z-index: 4;
}
.layerInfoPanel .ol-popup-closer {
  padding: 5px;
  padding-right: 1.5em;
  cursor: pointer;
}
.layerInfoPanel .panel {
  margin: 0;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  max-height: inherit;
  height: 100%;
  box-sizing: border-box;
}
.layerInfoName {
  font-weight: bold;
  font-size: large;
}
#sidebar > .ol-control > button, #extentLink, #metadataLink, #buttonBar > .ol-control.report-button button {
    color: #fff;
    background-color: #0060df;
    height: 2em;
    width: unset;
    padding: 0.5em;
    font-size: medium;
}
.opacityLabel {
    display: inline-block;
    clear:left;
    vertical-align: top;
    padding-top: 0.35em;
}
.layer-switcher .opacitySlider {
    display: inline-block;
    padding-top: 0.5em;
    margin-left: 0.5em;
    margin-right: 1em;
}
output {
    display: inline-block;
    vertical-align: top;
    padding-top: 0.35em;
}
.ol-control.report-button {
  position: absolute;
  right: 0.4em;
  margin-top: 0.25em;
}

.ol-control.report-button > button {
    width: auto;
    height: 2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-radius: 0.25rem;
}

.ol-control button.toggle {
  width: calc(100% - 2em);
  padding: 1em;
  height: inherit;
  text-align: left;
  margin: 1em;
  box-sizing: border-box;
}

.report-panel {
  box-sizing: border-box;
  width: calc(100% - 1.5em);
  max-width: calc(100% - 1.5em);
  height: calc(100% - 7.5em);
  background-color: white;
  position: absolute;
  top: 7em;
  right: 0.65em;
  position: absolute;
  background-color: white;
  visibility: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  z-index: 4;
}

.report-panel:hover {
    background-color: rgba(255,255,255,1);
}

.report-panel .ol-popup-closer {
  padding: 5px;
  cursor: pointer;
  position: unset;
}

.report-panel header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ddd;
}

.report-panel header h1 {
  margin: 0.25em;
  font-size: 1.4em;
}

.report-panel .panel {
  height: 100%;
  margin: 0;
  padding: 5px;
  background-color: white;
  box-sizing: border-box;
}

.report-panel .panel {
    overflow-y: scroll;
}

.report-panel .panel h1 {
  font-size: 1.3rem;
}

.report-panel .panel h2 {
  font-size: 1.1rem;
}

.report-panel table {
    border: 0px solid purple;
    border-collapse: collapse;
    table-layout: fixed;
}

.report-panel table th:nth-child(1) {
  width: 30%;
}

.report-panel th {
  vertical-align: top;
}

.report-panel table tr {
  border: 1px solid #ddd;
  border-width: 1px 0
}

.report-panel table tr:first-child {
  border: 0;
}

.report-panel table tr:last-child {
  border-top: 1px solid #aaa;
  border-bottom: 0;
}

.report-panel table td, table th {
  padding: 0.5em;
  text-align: left;
  vertical-align: top;
}

.datazoneName {
    background-color: rgba(0, 60, 136, 0.05)
}

.report-panel table td.numberAlign, table th.numberAlign {
    text-align: right;
    width: 10%;
}

.report-panel table td.numberAlign {
    white-space: nowrap;
}

#sidebar .ol-control button.toggle.closer {
    background-color: rgba(66, 66, 66, 0.6);
    margin-top: 3em;
}

#sidebar .layer-switcher button {
    background-color: white;
}

.total {
    font-weight: bold;
    border-top: 1px solid #ccc;
}

/* TODO Create a generic panel class which can be used by measure/ report/ layer info etc. */

.welcome-panel {
  height: calc(100% - 8em);
  box-sizing: border-box;
  width: calc(100% - 1.5em);
  max-width: calc(100% - 1.5em);
  position: absolute;
  top: 7em;
  right: 0.65em;
  position: absolute;
  background-color: white;
  visibility: visible;
  border: 1px solid #ddd;
  border-radius: 4px;
  /* display: flex; */
  /* flex-direction: column; */
  z-index: 4;
  overflow-y: scroll;
}

.welcome-panel:hover {
    background-color: rgba(255,255,255,1);
}

.welcome-panel .ol-popup-closer {
  padding: 5px;
  cursor: pointer;
  position: unset;
}

.welcome-panel header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ddd;
}

.welcome-panel header h1 {
  margin: 0.25em;
  font-size: 1.4em;
}

.welcome-panel .panel {
  height: calc(100% - 3.5em);
  margin: 0;
  padding: 5px;
  background-color: white;
  box-sizing: border-box;
}

.measure-panel {
  box-sizing: border-box;
  width: calc(100% - 1.5em);
  max-width: calc(100% - 1.5em);
  min-height: calc(100% - 3.5em);
  background-color: white;
  position: absolute;
  top: 7em;
  right: 0.75em;
  position: absolute;
  background-color: white;
  visibility: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
  /* display: flex; */
  /* flex-direction: column; */
  z-index: 4;
}

.measure-panel:hover {
    background-color: rgba(255,255,255,1);
}

.measure-panel .ol-popup-closer {
  padding: 5px;
  cursor: pointer;
  position: unset;
}

.measure-panel header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ddd;
}

.measure-panel header h1 {
  margin: 0.25em;
  font-size: 1.4em;
}

.measure-panel .panel {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 5px;
  background-color: white;
  box-sizing: border-box;
}
.measure-panel .panel main {
    width: 100%;
}
.measure-panel dl {
    width: 80%;
}
.measure-panel dt, .measure-panel dd {
    display: block;
    padding-bottom: 2em;
}
.measure-panel dt {
    clear: left;
}

div.oli-gazetteer.ol-control {
    position: absolute;
    top: 3.5em;
    width: calc(100% - 1.5em);
    max-width: 100%;
    z-index: 5;
}

.oli-gazetteer .autocomplete__input {
    border-width: 1px;
    border-color: #ddd;
    padding: 0.5em;
    font-size: medium;
}

.layerDate {
    color: #999;
    padding-top: 0.5em;
    padding-bottom: 1em;
}

.layerAbstract {
    display: block;
    padding-bottom: 1em;
    line-height: 1.5em;
}

#ogcList {
    padding-top: 2em;
    width: 100%;
}

#ogcList h1 {
    font-size: medium;
}

#ogcList td {
    padding-bottom: 1em;
}

#ogcList td:first-child, #ogcList td:nth-child(3) {
    width: 10%;
}

#ogcList td:nth-child(2), #ogcList td:nth-child(3), #ogcList td:nth-child(3) a {
    color: #999;
    cursor: pointer;
    text-decoration: none;
}

#ogcList td:nth-child(2):hover, #ogcList td:nth-child(3):hover, #ogcList td:nth-child(3) a:hover {
    color: #333399;
}

#ogcList td:nth-child(3) {
    font-size: medium;
}

#ogcList input {
    width: calc(100% - 1em);
    color: #999;
}

#ogcList ul, #sidebar div#metadataDiv button {
    padding-left: 1em;
}
#ogcList ul li {
    padding-right: 1em;
}
#ogcList td:nth-child(3) {
    font-family: 'Material Icons Outlined';
}

#sidebar #extentDiv {
    float: left;
}

#metadataDiv button {
    margin-left: 1em;
}

#sidebar #extentDiv button, #sidebar #metadataDiv button {
    display: inline;
    width: auto;
    margin-top: 1em;
    padding: 0.5em;
    height: 2em;
    border-radius: 0.25rem;
    background-color: #0060df;
    background-image: none;
    float: none;
}

div.ol-scale-bar {
    top: 1.5em;
    right: 2em;
    left: unset;
    bottom: unset;
}

#map .ol-attribution:not(.ol-collapsed) {
    max-width: calc(100% - 175px);
    background-color: rgba(255,255,255,0.5);
}

#map .ol-attribution ul {
    color: #333;
}

.ol-attribution button {
    display: none;
}

.legendWrapper {
    position: relative;
    margin-top: 1em;
    margin-bottom: 1em;
}

.legendIcons {
    position: absolute;
    top: 0em;
    left: 0em;
    bottom: 0em;
    right: 0em;
    width: 60px;
    z-index: 2;
    overflow-x: hidden;
    overflow-y: visible;
    background-color: white;
}

div.panelLegendIcons {
    width: 45px;
}

div.mktoast-message__body {
    padding-right: 20px;
}

div.mktoast__board.mk-left {
  left: 2em;
}
#map.mapPrint {
    width: 275mm;
    height: 194mm;
}

@media screen {
    .resultScreen {
        display: block;
    }
    .resultPrint {
        display: none;
    }
}
@media screen and (max-width: 767.98px) {
    body #map {
        width: 100%;
    }
    body #map.sidebarOpen {
        width: 100%;
    }
    #map .ol-control.oli-history {
        left: 3em;
    }
    body #sidebar {
        display: none;
    }
    body #sidebar.sidebarOpen {
        display: block;
        width: 100%;
    }
    #sidebar.sidebarOpen div.oli-gazetteer.ol-control {
        width: calc(100% - 4.5em);
        right: 3.5em;
    }
    #sidebarCloser {
        display: block;
        position: absolute;
        top: 1em;
        right: 1em;
        z-index: 5;
    }
    body #sidebarButton {
        display: block;
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        font-size: xx-large;
    }
    #printReportButton {
         display: none;
     }
     body .ol-control.btn-share {
      left: 3.15em;
    }
    body .ol-control.btn-help {
      left: 5.8em;
    }
    body .ol-control.btn-print {
      display: none;
    }
    body .ol-control.btn-reset {
      left: 8.45em;
    }
    body .ol-control.btn-docs {
      left: 11.1em;
    }
    body .ol-control.report-button {
        right: 3em;
    }
    body .pulse {
        box-shadow: 0 0 0 0 rgba(0, 96, 223, 1);
        transform: scale(1);
        animation: pulser 2s infinite;
    }
    body .ol-overviewmap {
        display: none;
    }
    body .ol-attribution {
        width: 100%;
    }
    #map div.ol-scale-bar {
        top: unset;
        right: unset;
        margin-top: unset;
        left: 0.5em;
        bottom: 0.5em;
    }
}
@media print {
    html, body {
        overflow-y:  visible;
    }
    #map tr {
        display: block;
    }
    .oli-gazetteer {
        display: none;
    }
    .layer-switcher.shown {
        top: 0.4em;
        display: none;
    }
    body #map, body #map.sidebarOpen {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
    }
    body #sidebarButton, body #sidebarButton.active {
        display: none!important;
    }
    body #buttonBar {
        display: none;
    }
    body #sidebar {
        display: none;
    }
    body #sidebar.reportPrint {
        display: block;
        position: relative;
        top: unset;
        right: unset;
        bottom: unset;
        width: 100%;
        z-index: -1;
        overflow-y: visible;
        margin-top: 1em;
    }
    body .welcome-panel {
        display: none;
    }
    body .report-panel {
        position: relative;
        top: unset;
        height: unset;
        width: 100%;
        overflow-y: visible;
        border: 0em;
    }
    body .report-panel .panel {
        height: unset;
        overflow-y: visible;
    }
    .ol-zoom, .report-button, #map .oli-history, .ol-popup-closer {
        display: none;
    }
    .resultScreen {
        display: none;
    }
    .resultPrint {
        display: block;
    }
    body .report-panel button {
        display: none;
    }
    body .ol-overviewmap {
        display: none;
    }
    body .ol-attribution {
        width: unset;
        max-width: unset;
        right: unset;
        left: 0.5em;
        text-align: left;
    }
    body #sidebarButton {
        display: none;
    }
}

@keyframes pulser {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 96, 223, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 96, 223, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 96, 223, 0);
	}
}
/*# sourceMappingURL=index.088921f0.css.map */
