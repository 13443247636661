html, body {
    height: 100%;
    padding: 0;
    margin: 0;
    overflow-y: hidden;
    font-family: sans-serif;
    font-size: small;
}
dl {
    margin-left: auto;
    margin-right: auto;
}
dt, dd {
    float: left;
}
dt {
    clear: left;
}

button {
    cursor: pointer;
}
table{
    display: block;
}

#map {
    position: absolute;
    top: 0em;
    left: 0em;
    height: 100%;
    width: calc(100% - 440px);
}
#map.sidebarOpen{
    width: calc(100% - 440px);
}
#sidebar {
    position: absolute;
    top: 0em;
    right: 0em;
    width: 440px;
    bottom: 0em;
    background-color: #ffffff;
    z-index: 3;
}

#sidebar.sidebarOpen {
    position: absolute;
    top: 0em;
    right: 0em;
    width: 440px;
    bottom: 0em;
    background-color: #ffffff;
}

#sidebarCloser {
    display: none;
}

#sidebarButton {
    z-index: 2;
    font-family: 'Material Icons Outlined';
    display: none;
}

#sidebarButton.active {
    display: block;
}

#sidebar .ol-control button {
    background-color: #0060df;
    color: #fff;
}

#sidebar .ol-control button:hover {
    outline: none;
}

#logo {
  display: block;
  width: calc(100% - 2em);
  height: auto;
  padding: 1em;
}

#getStarted {
    margin-top: 2em;
    margin-bottom: 2em;
    color: #fff;
}

#buttonBar {
    position: absolute;
    top: 0.4em;
    left: 0.4em;
    right: 0.4em;
    height: 3em;
}

.ol-control.btn-control.btn-active button {
  background-color: rgba(0,60,136,0.75);
}

.ol-control.control-btn-lnk a {
  display: block;
  margin: 1px;
  padding: 0;
  color: white;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  line-height: 1em;
  background-color: rgba(0,60,136,0.5);
  border: none;
  border-radius: 2px;
  padding: 0.25em;
}

#buttonBar .ol-control button, #buttonBar .ol-control a {
    font-size: large;
    background-color: #fff;
    color: #000;
}

#buttonBar .ol-control {
    padding: 0em;
    background: none;
}

#buttonBar .ol-control.btn-measure {
    left: 0.5em;
    height: 2em;
    padding-top: 0.25em;
    transform: rotate(-45deg);
}
#buttonBar .ol-control.btn-measure button, #buttonBar .ol-control.btn-share a, #buttonBar .ol-control.btn-help a, #buttonBar .ol-control.btn-docs a, #buttonBar .ol-control.btn-reset a, #buttonBar .ol-control.btn-print a, #buttonBar .ol-control.oli-history button {
    font-family: 'Material Icons Outlined';
    font-size: x-large;
    font-weight: normal;
    font-style: normal;
    color: #999;
}

.ol-control.oli-history button {
    font-family: 'Material Icons Outlined';
}
#buttonBar .ol-control.btn-measure button:hover, #buttonBar .ol-control.btn-share a:hover, #buttonBar .ol-control.btn-help a:hover, #buttonBar .ol-control.btn-docs a:hover, #buttonBar .ol-control.btn-reset a:hover, #buttonBar .ol-control.btn-print a:hover, #buttonBar .ol-control.oli-history button:hover {
    color: #666;
}

.ol-control.btn-share {
  left: 3.25em;
}

.ol-control.btn-help {
  left: 6em;
}

.ol-control.btn-print {
  left: 8.75em;
}

.ol-control.btn-reset {
  left: 11.5em;
}

.ol-control.btn-docs {
  left: 14.25em;
}

#sidebar .layer-switcher.shown {
  max-height: unset;
}
.layer-switcher.shown {
  box-sizing: border-box;
  top: 7em;
  right: 0.65em;
  width: calc(100% - 1.5em);
  max-width: calc(100% - 1.5em);
  height: calc(100% - 8em);
}

.layer-switcher.shown .panel {
  overflow-y: scroll;
  border-width: 1px;
  border-color: #ddd;
}
.layer-switcher.shown.layer-switcher-activation-mode-click > button {
    display: none;
}
.layer-switcher.shown.layer-switcher-activation-mode-click {
    padding-left: inherit;
}
.layer-switcher.shown .panel ul {
  overflow-y: visible;
}
#sidebar .layer-switcher-close button {
    margin-top: 4px;
}
#sidebar .layer-switcher li input {
    margin-top: 4px;
}
#sidebar .layer-switcher li label {
    margin-top: 3px;
}
.layer-switcher .layer a {
  position: absolute;
  right: 0em;
  top: 0em;
  text-decoration: none;
  font-size: large;
  padding-right: 0.5em;
  font-family: 'Material Icons Outlined';
  color: #aaa;
}

.layer-switcher .layer a:hover {
  color: #666;
}

.layerInfoPanel {
  position: absolute;
  top: 0em;
  left: 0em;
  bottom: 0em;
  right: 0em;
  background-color: white;
  visibility: hidden;
  z-index: 4;
}
.layerInfoPanel .ol-popup-closer {
  padding: 5px;
  padding-right: 1.5em;
  cursor: pointer;
}
.layerInfoPanel .panel {
  margin: 0;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  max-height: inherit;
  height: 100%;
  box-sizing: border-box;
}
.layerInfoName {
  font-weight: bold;
  font-size: large;
}
#sidebar > .ol-control > button, #extentLink, #metadataLink, #buttonBar > .ol-control.report-button button {
    color: #fff;
    background-color: #0060df;
    height: 2em;
    width: unset;
    padding: 0.5em;
    font-size: medium;
}
.opacityLabel {
    display: inline-block;
    clear:left;
    vertical-align: top;
    padding-top: 0.35em;
}
.layer-switcher .opacitySlider {
    display: inline-block;
    padding-top: 0.5em;
    margin-left: 0.5em;
    margin-right: 1em;
}
output {
    display: inline-block;
    vertical-align: top;
    padding-top: 0.35em;
}
.ol-control.report-button {
  position: absolute;
  right: 0.4em;
  margin-top: 0.25em;
}

.ol-control.report-button > button {
    width: auto;
    height: 2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-radius: 0.25rem;
}

.ol-control button.toggle {
  width: calc(100% - 2em);
  padding: 1em;
  height: inherit;
  text-align: left;
  margin: 1em;
  box-sizing: border-box;
}

.report-panel {
  box-sizing: border-box;
  width: calc(100% - 1.5em);
  max-width: calc(100% - 1.5em);
  height: calc(100% - 7.5em);
  background-color: white;
  position: absolute;
  top: 7em;
  right: 0.65em;
  position: absolute;
  background-color: white;
  visibility: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  z-index: 4;
}

.report-panel:hover {
    background-color: rgba(255,255,255,1);
}

.report-panel .ol-popup-closer {
  padding: 5px;
  cursor: pointer;
  position: unset;
}

.report-panel header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ddd;
}

.report-panel header h1 {
  margin: 0.25em;
  font-size: 1.4em;
}

.report-panel .panel {
  height: 100%;
  margin: 0;
  padding: 5px;
  background-color: white;
  box-sizing: border-box;
}

.report-panel .panel {
    overflow-y: scroll;
}

.report-panel .panel h1 {
  font-size: 1.3rem;
}

.report-panel .panel h2 {
  font-size: 1.1rem;
}

.report-panel table {
    border: 0px solid purple;
    border-collapse: collapse;
    table-layout: fixed;
}

.report-panel table th:nth-child(1) {
  width: 30%;
}

.report-panel th {
  vertical-align: top;
}

.report-panel table tr {
  border: 1px solid #ddd;
  border-width: 1px 0
}

.report-panel table tr:first-child {
  border: 0;
}

.report-panel table tr:last-child {
  border-top: 1px solid #aaa;
  border-bottom: 0;
}

.report-panel table td, table th {
  padding: 0.5em;
  text-align: left;
  vertical-align: top;
}

.datazoneName {
    background-color: rgba(0, 60, 136, 0.05)
}

.report-panel table td.numberAlign, table th.numberAlign {
    text-align: right;
    width: 10%;
}

.report-panel table td.numberAlign {
    white-space: nowrap;
}

#sidebar .ol-control button.toggle.closer {
    background-color: rgba(66, 66, 66, 0.6);
    margin-top: 3em;
}

#sidebar .layer-switcher button {
    background-color: white;
}

.total {
    font-weight: bold;
    border-top: 1px solid #ccc;
}

/* TODO Create a generic panel class which can be used by measure/ report/ layer info etc. */

.welcome-panel {
  height: calc(100% - 8em);
  box-sizing: border-box;
  width: calc(100% - 1.5em);
  max-width: calc(100% - 1.5em);
  position: absolute;
  top: 7em;
  right: 0.65em;
  position: absolute;
  background-color: white;
  visibility: visible;
  border: 1px solid #ddd;
  border-radius: 4px;
  /* display: flex; */
  /* flex-direction: column; */
  z-index: 4;
  overflow-y: scroll;
}

.welcome-panel:hover {
    background-color: rgba(255,255,255,1);
}

.welcome-panel .ol-popup-closer {
  padding: 5px;
  cursor: pointer;
  position: unset;
}

.welcome-panel header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ddd;
}

.welcome-panel header h1 {
  margin: 0.25em;
  font-size: 1.4em;
}

.welcome-panel .panel {
  height: calc(100% - 3.5em);
  margin: 0;
  padding: 5px;
  background-color: white;
  box-sizing: border-box;
}

.measure-panel {
  box-sizing: border-box;
  width: calc(100% - 1.5em);
  max-width: calc(100% - 1.5em);
  min-height: calc(100% - 3.5em);
  background-color: white;
  position: absolute;
  top: 7em;
  right: 0.75em;
  position: absolute;
  background-color: white;
  visibility: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
  /* display: flex; */
  /* flex-direction: column; */
  z-index: 4;
}

.measure-panel:hover {
    background-color: rgba(255,255,255,1);
}

.measure-panel .ol-popup-closer {
  padding: 5px;
  cursor: pointer;
  position: unset;
}

.measure-panel header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ddd;
}

.measure-panel header h1 {
  margin: 0.25em;
  font-size: 1.4em;
}

.measure-panel .panel {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 5px;
  background-color: white;
  box-sizing: border-box;
}
.measure-panel .panel main {
    width: 100%;
}
.measure-panel dl {
    width: 80%;
}
.measure-panel dt, .measure-panel dd {
    display: block;
    padding-bottom: 2em;
}
.measure-panel dt {
    clear: left;
}

div.oli-gazetteer.ol-control {
    position: absolute;
    top: 3.5em;
    width: calc(100% - 1.5em);
    max-width: 100%;
    z-index: 5;
}

.oli-gazetteer .autocomplete__input {
    border-width: 1px;
    border-color: #ddd;
    padding: 0.5em;
    font-size: medium;
}

.layerDate {
    color: #999;
    padding-top: 0.5em;
    padding-bottom: 1em;
}

.layerAbstract {
    display: block;
    padding-bottom: 1em;
    line-height: 1.5em;
}

#ogcList {
    padding-top: 2em;
    width: 100%;
}

#ogcList h1 {
    font-size: medium;
}

#ogcList td {
    padding-bottom: 1em;
}

#ogcList td:first-child, #ogcList td:nth-child(3) {
    width: 10%;
}

#ogcList td:nth-child(2), #ogcList td:nth-child(3), #ogcList td:nth-child(3) a {
    color: #999;
    cursor: pointer;
    text-decoration: none;
}

#ogcList td:nth-child(2):hover, #ogcList td:nth-child(3):hover, #ogcList td:nth-child(3) a:hover {
    color: #333399;
}

#ogcList td:nth-child(3) {
    font-size: medium;
}

#ogcList input {
    width: calc(100% - 1em);
    color: #999;
}

#ogcList ul, #sidebar div#metadataDiv button {
    padding-left: 1em;
}
#ogcList ul li {
    padding-right: 1em;
}
#ogcList td:nth-child(3) {
    font-family: 'Material Icons Outlined';
}

#sidebar #extentDiv {
    float: left;
}

#metadataDiv button {
    margin-left: 1em;
}

#sidebar #extentDiv button, #sidebar #metadataDiv button {
    display: inline;
    width: auto;
    margin-top: 1em;
    padding: 0.5em;
    height: 2em;
    border-radius: 0.25rem;
    background-color: #0060df;
    background-image: none;
    float: none;
}

div.ol-scale-bar {
    top: 1.5em;
    right: 2em;
    left: unset;
    bottom: unset;
}

#map .ol-attribution:not(.ol-collapsed) {
    max-width: calc(100% - 175px);
    background-color: rgba(255,255,255,0.5);
}

#map .ol-attribution ul {
    color: #333;
}

.ol-attribution button {
    display: none;
}

.legendWrapper {
    position: relative;
    margin-top: 1em;
    margin-bottom: 1em;
}

.legendIcons {
    position: absolute;
    top: 0em;
    left: 0em;
    bottom: 0em;
    right: 0em;
    width: 60px;
    z-index: 2;
    overflow-x: hidden;
    overflow-y: visible;
    background-color: white;
}

div.panelLegendIcons {
    width: 45px;
}

div.mktoast-message__body {
    padding-right: 20px;
}

div.mktoast__board.mk-left {
  left: 2em;
}
#map.mapPrint {
    width: 275mm;
    height: 194mm;
}

@media screen {
    .resultScreen {
        display: block;
    }
    .resultPrint {
        display: none;
    }
}
@media screen and (max-width: 767.98px) {
    body #map {
        width: 100%;
    }
    body #map.sidebarOpen {
        width: 100%;
    }
    #map .ol-control.oli-history {
        left: 3em;
    }
    body #sidebar {
        display: none;
    }
    body #sidebar.sidebarOpen {
        display: block;
        width: 100%;
    }
    #sidebar.sidebarOpen div.oli-gazetteer.ol-control {
        width: calc(100% - 4.5em);
        right: 3.5em;
    }
    #sidebarCloser {
        display: block;
        position: absolute;
        top: 1em;
        right: 1em;
        z-index: 5;
    }
    body #sidebarButton {
        display: block;
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        font-size: xx-large;
    }
    #printReportButton {
         display: none;
     }
     body .ol-control.btn-share {
      left: 3.15em;
    }
    body .ol-control.btn-help {
      left: 5.8em;
    }
    body .ol-control.btn-print {
      display: none;
    }
    body .ol-control.btn-reset {
      left: 8.45em;
    }
    body .ol-control.btn-docs {
      left: 11.1em;
    }
    body .ol-control.report-button {
        right: 3em;
    }
    body .pulse {
        box-shadow: 0 0 0 0 rgba(0, 96, 223, 1);
        transform: scale(1);
        animation: pulser 2s infinite;
    }
    body .ol-overviewmap {
        display: none;
    }
    body .ol-attribution {
        width: 100%;
    }
    #map div.ol-scale-bar {
        top: unset;
        right: unset;
        margin-top: unset;
        left: 0.5em;
        bottom: 0.5em;
    }
}
@media print {
    html, body {
        overflow-y:  visible;
    }
    #map tr {
        display: block;
    }
    .oli-gazetteer {
        display: none;
    }
    .layer-switcher.shown {
        top: 0.4em;
        display: none;
    }
    body #map, body #map.sidebarOpen {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
    }
    body #sidebarButton, body #sidebarButton.active {
        display: none!important;
    }
    body #buttonBar {
        display: none;
    }
    body #sidebar {
        display: none;
    }
    body #sidebar.reportPrint {
        display: block;
        position: relative;
        top: unset;
        right: unset;
        bottom: unset;
        width: 100%;
        z-index: -1;
        overflow-y: visible;
        margin-top: 1em;
    }
    body .welcome-panel {
        display: none;
    }
    body .report-panel {
        position: relative;
        top: unset;
        height: unset;
        width: 100%;
        overflow-y: visible;
        border: 0em;
    }
    body .report-panel .panel {
        height: unset;
        overflow-y: visible;
    }
    .ol-zoom, .report-button, #map .oli-history, .ol-popup-closer {
        display: none;
    }
    .resultScreen {
        display: none;
    }
    .resultPrint {
        display: block;
    }
    body .report-panel button {
        display: none;
    }
    body .ol-overviewmap {
        display: none;
    }
    body .ol-attribution {
        width: unset;
        max-width: unset;
        right: unset;
        left: 0.5em;
        text-align: left;
    }
    body #sidebarButton {
        display: none;
    }
}

@keyframes pulser {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 96, 223, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 96, 223, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 96, 223, 0);
	}
}